<style lang="scss">
@import "~@/assets/css/var";
.commont-table-pdf_content{
    height: 70vh;
    overflow: auto;
}
.compare-table-pdf {
    // width: 2000px;
    // width: 100%;
    * {
        box-sizing: border-box;
    }

    .panel-left {
        &.scroll {
            box-shadow: 8px 0 5px -4px rgba(0, 0, 0, .12);
        }
        &::-webkit-scrollbar {
            display: none;
        }
        .compare-head {
            display: flex;
            .compare-unit {
                background: $mainLight;
                border-color: #fff;
                color: #fff;
                display: flex;
                align-items: center;
            }
        }

        .compare-unit-left {
            width: 200px;
        }
    }

    .my-input {

        .el-input__inner {
            border: 0;
            padding: 0 10px;
            text-align: center;
        }
    }

    .up {
        color: $red;
        font-weight: bold;
    }

    .down {
        color: $green;
        font-weight: bold;
    }

    .empty {
        padding-top: 200px;
        font-size: 20px;
        color: #999;
        text-align: center;
    }
   
    .compare-body {
        display: grid;
        //border-right: 1px solid $border;
    }

    .compare-item {
        display: flex;
        align-items: stretch;
        font-size: 0;
        white-space: nowrap;
        border-left: 1px solid $border;
        border-bottom: 1px solid $border;

        &.compare-block {
            background: $border;
        }
    }

    .compare-unit {
        position: relative;
        display: inline-block;
        width: 300px;
        flex-shrink: 0;
        font-size: 12px;
        padding: 8px 6px;
        border-right: 1px solid $border;
        word-break: break-all;
        white-space: normal;
        vertical-align: middle;
        min-height: 36px;

        .el-icon-date {
            position: absolute;
            color: $main;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .compare-block {
        color: $main;
        font-weight: bold;
        font-size: 14px;
        border-color: transparent;
    }
}
</style>

<template>
    <div class="compare-table-pdf" :style="{ width: (200 + list.length * 300) + 'px' }">
        <div ref="left" class="panel-left" :class="{ scroll: isSrollRight }">
            <div class="compare-head">
                <div class="compare-unit compare-unit-left">药品参数</div>
                <div class="compare-unit" v-for="item in list" :key="item.id">
                    {{ item.comName }} {{ item.spec }} {{ item.compName }}
                    <i class="el-icon-loading" v-if="item.loading"></i>
                </div>
            </div>
            <div class="compare-body">
                <div class="compare-item" :class="{ 'compare-block': col.block }" v-for="col in cols" :key="col.key">
                    <div class="compare-unit compare-unit-left" :class="{ 'compare-block': col.block }">{{ col.value }}</div>
                    <div class="compare-unit" :class="{ 'compare-block': col.block }" v-for="item in list" :key="item.id">
                        <slot :name="'slot_' + col.key" :row="item" :col="col">
                            <div style="display: inline-block"
                                v-html="item.real[col.key] || (col.block ? '' : '--') + (col.suffix || '')"></div>&nbsp;
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 是否滚动到右边
            isSrollRight: false
        }
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        cols: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        panelScroll(e) {
            this.$refs.head.scrollLeft = e.target.scrollLeft
            this.$refs.left.scrollTop = e.target.scrollTop
            this.isSrollRight = e.target.scrollLeft > 2
        },
        panelLeftScroll(e) {
            this.$refs.panel.scrollTop = e.target.scrollTop
        },
    }
}
</script>